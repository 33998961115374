<div class="container">
  <div class="row">
    <div class="footer_dropdown_wrapper">

      <div class="select-lang dropdown">
        <a href="#" class="dropdown-toggle current-lang" data-bs-toggle="dropdown" role="button" aria-expanded="false">
          <img class="img-responsive" src="assets/img/country/{{selectedLanguage || 'IT'}}.png" style="max-width: 30px;" />
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu arrow-center user-menu lang-list" role="menu">
          <!-- <li *ngFor="let lang of avaibleLangs" class="lang-item"><a class="nav-link">{{ lang.text }}</a></li> -->
          <li *ngFor="let lang of avaibleLangs" class="lang-item" (click)="setLang(lang.code)">
            <a class="nav-link">
              <img class="img-responsive" src="assets/img/country/{{lang.code}}.png" style="max-width: 30px;" />
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>
