import { Component, OnInit, ViewChild, ViewEncapsulation, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AppService } from '../../../core/services/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SalesService } from '../../../services/sales.service';
import { RateService } from '../../../services/rate.service';
import { SharedService } from '../../../shared/shared.service';
import { ExcelService } from '../../../shared/excel.service';
import { SellerService } from '../../../services/seller.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';;
import { Subscription } from 'rxjs';
import moment from 'moment';
import swal from 'sweetalert2';
import * as _ from 'lodash';

moment.locale('it');

@Component({
  selector: 'app-refund-analysis',
  templateUrl: './refund-analysis.component.html',
  styleUrls: ['./refund-analysis.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RefundAnalysisComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable') table: any;

  allStatus: any = [
    { "name": "attiva" },
    { "name": "aperta" },
  ]

  @Output() deletedChip = new EventEmitter<any>();

  activePage: String = "sa";
  rows: any[] = [];
  totalRows: number = 0;
  queryParamsSearch: any;
  queryParamsDate: any;
  filterQueryParameterUrl: any;

  selectedUser : any;

  states = [
    { 'state': 'final', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false }];

  intervalOptions = [
    {
      "id": 1, "name": "advance-search.one", "removedMonth": 1
    }, 
    {
      "id": 2, "name": "advance-search.three", "removedMonth": 6
    }
    , {
      "id": 3, "name": "advance-search.six", "removedMonth": 12
    }
  ]

  selectOptions = [
    {
      "id": 1, "name": "Amministratore", "type": "word", "typeOfSearch": "level"
    }, {
      "id": 2, "name": "Rivenditore", "type": "word", "typeOfSearch": "level"
    }, {
      "id": 3, "name": "Gestore", "type": "word", "typeOfSearch": "level"
    },
    {
      "id": 4, "name": "Responsabile", "type": "word", "typeOfSearch": "level"
    },
    {
      "id": 5, "name": "Agente", "type": "word", "typeOfSearch": "level"
    }
  ]

  options: any = {};
  allRates: any;
  tableRecordNumbers: number;
  tablePage: number = 1;

  newVal : boolean;
  oldVal : boolean = false;
  profile : any;
  merchantDetail : any;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  enableSendReminder: boolean;

  searchForm: UntypedFormGroup;
  sortForm: UntypedFormGroup;

  notifySelectedUserChange : Subscription;

  constructor(
    private excelService: ExcelService,
    private sharedService: SharedService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private salesService: SalesService,
    private rateService: RateService,
    private translate: TranslateService, 
    private logger : LoggingService , 
    private sellerService : SellerService,
    private formBuilder: UntypedFormBuilder
  ) {
      this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);

      // Overrides default total/empty messages
      translate.get("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );
      translate.get("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);

      this.searchForm = this.formBuilder.group({
        customerName: [null],
        customerSurname: [null],
        customerEmail: [null],
        amountFrom: [null],
        amountTo: [null],
        //dateRangeRequest: [{ startDate: moment().subtract(30, 'days'), endDate: moment() }],
        //dateFromRequest: [{ startDate: moment().subtract(30, 'days'), endDate: moment().subtract(30, 'days') }],
        //dateToRequest: [{ startDate: moment(), endDate: moment() }],
        dateRangeRequest: [[ moment().subtract(30, 'days').toDate(), moment().toDate() ]],
        //dateRangeRefund: [null],
        //dateFromRefund: [null],
        //dateToRefund: [null],
        dateRangeRefund: [null],
        status: [null],
        userEmail: [null],
        idMerchant: [null],
        idExternal: [null]
      });

      this.sortForm = this.formBuilder.group({
        viewRefund: [false],
        viewDispute: [false]
      });
  }

  ngOnInit() {
    this.profile = localStorage.getItem('profile')
    localStorage.setItem( "saleDate" , "1" )

    /*if (!this.queryParamsDate){
      this.queryParamsDate = this.initFirstCallRangeDates();
    }*/
    // this.initRateTable()

    this.selectedUser = JSON.parse( localStorage.getItem("selectedUser") )
    
    /*this.getAllRates( "startDateRequest=" + moment().subtract(30, 'days').format("YYYY-MM-DD") +
      "&endDateRequest=" + moment().format("YYYY-MM-DD"),
      this.selectedUser ? this.selectedUser.idMerchant : null );*/
    this.prepareForSearch();

    this.notifySelectedUserChange = this.sharedService.selectedUserChanged.subscribe((value) => {
      this.selectedUser = JSON.parse( localStorage.getItem("selectedUser") )

      if (this.selectedUser) {
        this.searchForm.controls.idMerchant.setValue(this.selectedUser.idMerchant);
        this.searchForm.controls.idMerchant.disable();
      } else {
        this.searchForm.controls.idMerchant.reset();
        this.searchForm.controls.idMerchant.enable();
      }

      this.prepareForSearch();
    })

    if( localStorage.getItem("profile") != "SUPERADMIN" && localStorage.getItem("profile") != "RESELLER") {
      this.getMerchantDetail()
    }
  }

  ngDoCheck() {
    this.newVal = this.hasSelectedAlias()
    
    if ( this.isValChanged( this.oldVal , this.newVal) ){
      this.oldVal = this.newVal
      // this.getAllRates( null , this.queryParamsDate , this.selectedUser ? this.selectedUser.idMerchant : null )
    } 
  }

  initRateTable( isFromEmit = null ) {
    this.options = {};
    if( !localStorage.getItem("saleDate") ) {
      this.filterQueryParameterUrl = "";
    }

    this.selectedUser = JSON.parse(localStorage.getItem("selectedUser"))
    if (this.selectedUser){
      this.options.userSelected = this.selectedUser
    }  

    if( localStorage.getItem("saleDate")) {
      this.options.dates = this.initFirstCallRangeDates( "chip" )
    }

    let statusUrl = localStorage.getItem("saleStatus");
    if (statusUrl == 'insoluti') {
      this.options.status = 'INSOLUTA'
      this.filterQueryParameterUrl = "status=INSOLUTA"
      // this.getAllRates(null, this.initFirstCallRangeDates(), this.selectedUser ? this.selectedUser.idMerchant : null);
    } else {
      if( isFromEmit == null ) {
        this.queryParamsDate = this.initFirstCallRangeDates()
      } else if ( isFromEmit != null ) {
        if( localStorage.getItem("saleDate") == null ) {
          this.queryParamsDate = null
        }
      }
      // this.getAllRates( null , this.queryParamsDate ? this.queryParamsDate : null , this.selectedUser ? this.selectedUser.idMerchant : null )
    }
    /* else {
      if (!this.queryParamsDate)
        this.queryParamsDate = this.initFirstCallRangeDates();
      this.hasSelectedAlias()
      this.getAllRates(null, this.queryParamsDate, this.selectedUser ? this.selectedUser.idMerchant : null);
    } */
  }

  async getAllRates(queryParams = null, id = null, exporting = false) {

    swal.showLoading();

    if (!queryParams) {
      queryParams = '';
    } else {
      queryParams += '&';
    }

    if (!exporting) {
      queryParams += 'paging=true&page=' + this.tablePage + '&rows=' + this.tableRecordNumbers;
    }

    const res = await this.rateService.getAllInstalmentRefunds(queryParams, id).toPromise();
    let results = [];
    if (res) {
      results = res.results;
      if (!exporting) {
        // this.rates = res.results;
        this.allRates = res.results;
        this.rows = this.allRates;
        this.totalRows = res.total;
        /*if (this.sortForm.controls.viewRefund.value || this.sortForm.controls.viewDispute.value) {
          this.rates = this.excludeFlagRates();
        }
        this.rates = [...this.rates];*/
      }
    }
    swal.close();
    return results;
  }

  getInstalmentStatus(row) {
    if (row.instalmentStatus == 'PAID' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY') {
      if (row.amount == row.refundedAmount) {
        return 'REFUNDED_FULLY';
      } else if (row.refundedAmount > 0) {
        return 'REFUNDED_PARTIALLY';
      }
    }
    return row.instalmentStatus;
  }

  isDebitInstalment(row) {
    return row.instalmentStatus == 'LOST_DISPUTE' ||
      row.instalmentStatus == 'PAID_LOST_DISPUTE' ||
      row.instalmentStatus == 'WON_DISPUTE' ||
      row.instalmentStatus == 'PAID_WON_DISPUTE' ||
      row.instalmentStatus == 'WITHDRAWN_DISPUTE' ||
      row.instalmentStatus == 'PAID_WITHDRAWN_DISPUTE';
  }

  excludeFlagRates() {
    let result : any = []
    this.allRates.forEach( element => {
      if( (this.sortForm.controls.viewRefund.value && !this.isRefund( element )) ||
        (this.sortForm.controls.viewDispute.value && !this.isDispute( element )) ) {
      } else {
        result.push(element)
      }
    })
    return result
  }

  onPage(event) {
    if (this.tablePage != event.offset + 1) {
      this.tablePage = event.offset + 1;
      this.getAllRates( this.getQueryParams(),
        this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant() );
    }
  }

  async toggleExpandRow(row : any = [] , expanded) {
    if (!expanded) {
      await this.getInstalmentDetails(row);
    }
    this.table.rowDetail.toggleExpandRow(row);
  }

  async getInstalmentDetails(row) {
    swal.showLoading();
    let res = await this.salesService.getInstalmentPaymentDetails(row.id).toPromise()
    row["installmentDetails"] = [res];
    swal.close();
    return res;
  }

  canViewDetails(row) {
    return row.paymentType != 'CHARGE_DISPUTE';
  }

  searchEvent(event) {
    console.log('searchEvent - event ', event);
    this.queryParamsDate = null;
    this.queryParamsSearch = this.getQueryParamsOfSearch(event);
    if( event ) {
      if( event.startDate && event.endDate ) {
        this.queryParamsDate = "startDate=" + event.startDate + "&endDate=" + event.endDate;
      }
    }
    // this.getAllRates(this.queryParamsSearch, this.queryParamsDate ? this.queryParamsDate : null , null)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  getQueryParamsOfSearch(searchItems) {
    let queryParamsSearch = "";
    if (searchItems.keyword)
      queryParamsSearch += "keyword=" + searchItems.keyword

    if (searchItems.searchFromSelect){
      if (searchItems.searchFromSelect.length > 0) {
        if (searchItems.keyword)
          queryParamsSearch += "&"
        queryParamsSearch += "profile=" + searchItems.searchFromSelect.join()
      }
    }
    return queryParamsSearch;
  }

  private getQueryParams() {
    let queryParamsSearch = "";
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (key != 'idMerchant') {
          /*if (key.startsWith('dateRange')) {
            if (this.searchForm.controls[key].value.startDate && this.searchForm.controls[key].value.endDate) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "startDate" + key.replace('dateRange', '') + "=" + this.searchForm.controls[key].value.startDate.format("YYYY-MM-DD");
              queryParamsSearch += "&"
              queryParamsSearch += "endDate" + key.replace('dateRange', '') + "=" + this.searchForm.controls[key].value.endDate.format("YYYY-MM-DD");
            }
          }*/
          if (key.startsWith('date')) {
            if (key.startsWith('dateFrom') && this.searchForm.controls[key].value.startDate &&
              this.searchForm.controls['dateTo' + key.replace('dateFrom', '')].value.endDate) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "startDate" + key.replace('dateFrom', '') + "=" + this.searchForm.controls[key].value.startDate.format("YYYY-MM-DD");
            } else if (key.startsWith('dateTo') && this.searchForm.controls['dateFrom' + key.replace('dateTo', '')].value.startDate &&
              this.searchForm.controls[key].value.endDate) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "endDate" + key.replace('dateTo', '') + "=" + this.searchForm.controls[key].value.endDate.format("YYYY-MM-DD");
            } else if (key.startsWith('dateRange') && this.searchForm.controls[key].value) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "startDate" + key.replace('dateRange', '') + "=" + moment(this.searchForm.controls[key].value[0]).format("YYYY-MM-DD") + "&" +
                "endDate" + key.replace('dateRange', '') + "=" + moment(this.searchForm.controls[key].value[1]).format("YYYY-MM-DD");
            }
          } else {
            if (Array.isArray(this.searchForm.controls[key].value)) {
              if (!!this.searchForm.controls[key].value.length) {
                if (queryParamsSearch) {
                  queryParamsSearch += "&"
                }
                queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
              }
            } else {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += key + "=" + this.searchForm.controls[key].value
            }
          }
        }
      }
    }

    let instalmentStatus = null;
    for (let key in this.sortForm.controls) {
      if (this.sortForm.controls[key].value) {
        switch (key) {
          case 'viewRefund':
            if (!instalmentStatus) {
              instalmentStatus = [];
            }
            const refundStatuses = ['PLANNED_REFUND', 'REFUNDED', 'PROCESSING_REFUND', 'ERROR_REFUND', 
              'PENDING_REFUND', 'WAITING_TOPUP_REFUND'];
            instalmentStatus.push(...refundStatuses);
            break;
          case 'viewDispute':
            if (!instalmentStatus) {
              instalmentStatus = [];
            }
            const disputeStatuses = ['LOST_DISPUTE', 'PAID_LOST_DISPUTE', 'WON_DISPUTE', 'PAID_WON_DISPUTE', 
              'WITHDRAWN_DISPUTE', 'PAID_WITHDRAWN_DISPUTE'];
            instalmentStatus.push(...disputeStatuses);
            break;
        }
      }
    }
    if (instalmentStatus) {
      if (queryParamsSearch) {
        queryParamsSearch += "&"
      }
      queryParamsSearch += "instalmentStatus=" + instalmentStatus
    }

    return queryParamsSearch;
  }

  private getIdMerchant() {
    let idMerchant = null;
    if (this.searchForm.controls['idMerchant'].value) {
      idMerchant = this.searchForm.controls['idMerchant'].value
    }
    return idMerchant;
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      this.tablePage = 1;
      this.getAllRates( this.getQueryParams(), 
        this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant() );
      if( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset({
      //dateRangeRequest: { startDate: moment().subtract(30, 'days'), endDate: moment() },
      //dateFromRequest: { startDate: moment().subtract(30, 'days'), endDate: moment().subtract(30, 'days') },
      //dateToRequest: { startDate: moment(), endDate: moment() },
      dateRangeRequest: [ moment().subtract(30, 'days').toDate(), moment().toDate() ],
      //dateRangeRefund: null,
      //dateFromRefund: null,
      //dateToRefund: null,
      dateRangeRefund: null,
      idMerchant: this.selectedUser ? this.selectedUser.idMerchant : null
    });
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  intervalEvent(event) {
    this.queryParamsDate = event;
    let selectedUser = JSON.parse(localStorage.getItem("selectedUser"))
    // this.getAllRates(this.queryParamsSearch, this.queryParamsDate, selectedUser ? selectedUser.idMerchant : null)
  }

  initFirstCallRangeDates( return_type = null) {
    var date = new Date();
    let today = this.formatDate(date);
    date.setDate(date.getDate() + 30);
    let beforeAWeek = this.formatDate(date);
    let beforeDate = this.formatDate(date);
    let queryParamsDate : any = "startDate=" + today + "&endDate=" + beforeDate;

    if ( return_type == null ){
      queryParamsDate = "startDate=" + today + "&endDate=" + beforeDate;
    } else if ( return_type == "obj" ) {
      queryParamsDate = { date : { startDate : today , endDate : beforeDate } }
    } else if ( return_type == "chip" ) {
      queryParamsDate = today + "/" + beforeDate
    }

    return queryParamsDate;
  }
  
  hasSelectedAlias() {
    var cur_use = JSON.parse(localStorage.getItem('selectedUser'))
    if ( cur_use != null && this.selectedUser == null ) {
      this.selectedUser = cur_use
      return true
    } else if ( cur_use != null && this.selectedUser != null ) {
      if( parseInt(this.selectedUser.idMerchant) !== parseInt(cur_use.idMerchant) ) {
        this.selectedUser = cur_use;
        this.oldVal = false
        return true
      }
    } else {
      this.selectedUser = null;
      return false
    }
  }

  /**
   * This function are utilities
   * @param date
   */
  formatDate(date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  statusSelectedEvent(event, row) {
    row.statusSelectOpened = false
    row.status = event.name
  }

  hideSelectEvent(row) {
    row.statusSelectOpened = false
  }

  async export() {
    const rows = await this.getAllRates( this.getQueryParams(), 
      this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant(),
      true );
    var data = [];
    var rateHeader = {};
    if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'RESELLER') {
      rateHeader = {
        merchant: 'Commerciante'
      }
    }
    rateHeader = {
      ...rateHeader,
      saleName: 'Nome vendita associata',
      name: 'Cognome Nome cliente',
      expiryDate: 'Data richiesta',
      tsPayment: 'Data rimborso / contest.',
      instalmentAmount: 'Importo rimborso / contest.',
      instalmentStatus: 'Stato rimborso / contest.',
      idPayment: 'Codice di pagamento'
    }
    if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'MANAGER') {
      rateHeader = {
        ...rateHeader,
        idRefund: 'Codice di rimborso'
      }
    }
    data.push(rateHeader)
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      var rate = {};
      if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'RESELLER') {
        rate = {
          merchant: element.merchantCompanyName
        }
      }
      rate = {
        ...rate,
        saleName: element.service ? element.service : 'Non disponibile',
        name: element.fullName ? element.fullName : element.surname + ' ' + element.name,
        expiryDate: element.expiryDate,
        tsPayment: element.tsPayment ? element.tsPayment : '',
        instalmentAmount : element.amount,
        instalmentStatus: element.instalmentStatus,
        idPayment: element.paymentId ? element.paymentId : ''
      }
      if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'MANAGER') {
        rate = {
          ...rate,
          idRefund: element.instalmentRefundId ? element.instalmentRefundId : ''
        }
      }
      data.push(rate)
    }
    this.excelService.export(data, 'Analisi Rimborsi Addebiti' + new Date().getTime())
  }

  redirectToEdit(row) {
  }

  chipDeleted() {
    this.deletedChip.emit()
  }

  isValChanged( old , current ){
    if( old == current ) {
      return false;
    } else {
      return true
    }
  }

  isRefund( element ) {
    return !this.isDebitInstalment(element);
  }

  isDispute( element ) {
    return this.isDebitInstalment(element);
  }

  onRefundExclusionChange( event ) {
    this.sortForm.controls.viewRefund.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onDisputeExclusionChange( event ) {
    this.sortForm.controls.viewDispute.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onFlagExclusionChange() {
    if (this.sortForm.controls.viewRefund.value || this.sortForm.controls.viewDispute.value) {
      this.rows = this.excludeFlagRates()
    } else {
      if( this.allRates ) {
        this.rows = this.allRates;
      }
    }
  }

  canCompleteRefundPayment( element ) {
    return localStorage.getItem("profile") == "SUPERADMIN";
  }

  getMerchantDetail() {
    this.sellerService.getSellerDetails( localStorage.getItem("idMerchant") ).subscribe((resp) => {
      this.merchantDetail = resp
    })
  }

  hasActionToShow( data ) {
    if( data.instalmentStatus == 'PENDING_REFUND' && this.canCompleteRefundPayment(data) ) {
      return true;
    } else {
      return false;
    }
  }

  completeRefundInstalment( data ) {
    swal.fire({
      title: this.translate.instant('sale.swal.complete_refund_instalment_title'), 
      text: this.translate.instant('sale.swal.complete_refund_instalment_text'), 
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: this.translate.instant('general.back'),
      confirmButtonText: this.translate.instant('general.continue')
    }).then((result) => {
      if( result.value ) {
        setTimeout(() => swal.showLoading(), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
        this.appService.editElement('/payment/instalment/refund/complete/'+data.id, null).subscribe((resp) => {
          setTimeout(() => swal.hideLoading(), 200)
          if( resp.outcome && resp.outcome.success ) {
            swal.fire({
              icon: "success",
              text: this.translate.instant('sale.swal.complete_refund_instalment_success')
            }).then((result) => {
              this.prepareForSearch()
            })
          } else {
            let errorMessage = null;
            switch (resp.code) {
              case "0037":
              case "0038":
              case "0039":
              case "0040":
              case "0041":
              case "0042":
              case "0052":
                errorMessage = this.settings.manageErrorMsg(resp)
                break;
              default:
                errorMessage = this.translate.instant('sale.swal.complete_refund_instalment_error')
                break;
            }
            swal.fire({
              icon: "error",
              text: errorMessage
            }).then((result) => {
              this.prepareForSearch()
            })
          }
        })
      } else if( result.dismiss ) {
        swal.close()
      }
    })
  }

  ngOnDestroy(){
    this.notifySelectedUserChange.unsubscribe()
  }
}
