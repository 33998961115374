@charset "UTF-8";

/* node_modules/@swimlane/ngx-datatable/themes/material.css */
.ngx-datatable.material {
  background: #fff;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover,
.ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus,
.ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active:focus,
.ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover,
.ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus,
.ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active,
.ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover,
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus,
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body {
  position: relative;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

/* node_modules/@swimlane/ngx-datatable/assets/icons.css */
@font-face {
  font-family: "data-table";
  src: url("./media/data-table.eot");
  src:
    url("./media/data-table.eot?#iefix") format("embedded-opentype"),
    url("./media/data-table.woff") format("woff"),
    url("./media/data-table.ttf") format("truetype"),
    url("./media/data-table.svg#data-table") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]::before {
  font-family: "data-table" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^=datatable-icon-]::before,
[class*=" datatable-icon-"]::before {
  font-family: "data-table" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.datatable-icon-filter::before {
  content: "b";
}
.datatable-icon-collapse::before {
  content: "a";
}
.datatable-icon-expand::before {
  content: "c";
}
.datatable-icon-close::before {
  content: "d";
}
.datatable-icon-up::before {
  content: "e";
}
.datatable-icon-down::before {
  content: "f";
}
.datatable-icon-sort-unset::before {
  content: "c";
  opacity: 0.5;
}
.datatable-icon-sort::before {
  content: "g";
}
.datatable-icon-done::before {
  content: "h";
}
.datatable-icon-done-all::before {
  content: "i";
}
.datatable-icon-search::before {
  content: "j";
}
.datatable-icon-pin::before {
  content: "k";
}
.datatable-icon-add::before {
  content: "m";
}
.datatable-icon-left::before {
  content: "o";
}
.datatable-icon-right::before {
  content: "p";
}
.datatable-icon-skip::before {
  content: "q";
}
.datatable-icon-prev::before {
  content: "r";
}

/* src/app/routes/sales/sale-analysis/sale-analysis.component.scss */
.list-font-weight {
  font-weight: 600;
  font-size: 15px;
  color: #a3a4aa;
}
.nav-border-bottom {
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-bottom-style: solid;
  padding-right: 500px;
  padding-left: 30px;
}
.body-position {
  padding-top: 30px;
}
.white-bc {
  background-color: white;
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 5px;
  margin-bottom: 30px;
}
.card-text1 {
  color: #a3a4aa;
  font-size: 13px;
}
.bold-card-text {
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
}
.bar-chart-position {
  display: block;
  position: relative;
  height: 12vh;
  width: 15vw;
}
.border-right {
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-right-style: solid;
}
.font-size-style {
  font-size: 12px;
  line-height: normal;
}
.ngx-datatable,
.info {
  text-align: left;
  width: 100%;
  margin: 0 auto;
}
.info {
  box-sizing: border-box;
  padding: 0 1em;
  border: solid 1px #ccc;
  background: white;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.head {
  margin: -20px;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #929292;
  padding-bottom: 70px;
  text-align: left;
  width: 75%;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
}
.font-size {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}
.table-font-size {
  color: #565864;
  font-size: 12px;
}
.title {
  padding-left: 30px;
  padding-top: 25px;
  font-size: 17px;
  font-weight: bold;
  color: #5c5e69;
}
.head-title {
  color: #787a84;
}
.expander-btn {
  margin-top: 8px;
  display: block;
}
.selection-cell .datatable-body-cell.active {
  background: #0829e0 !important;
  color: #fff !important;
}
.selected-column {
  float: right;
  width: 20%;
  margin-right: 20px;
  text-align: left;
}
.selected-column h4 {
  text-align: center;
  margin: 10px 0 0 0;
  padding: 0;
}
.selection-cell .datatable-body-cell.active {
  background: #0829e0 !important;
  color: #fff !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.dropdown-toggle.blue-bg {
  border-radius: 4px;
  cursor: pointer;
  height: 32px;
}
.dropdown-toggle.blue-bg::after {
  margin-left: -2px;
}
.dropdown-toggle.blue-bg:hover::after {
  color: #ffffff !important;
}
.user-menu.show {
  display: block;
  margin-top: 5px;
}
.edit {
  color: #666666;
  padding: 3px 10px;
}
.seco-text-color {
  color: #666666;
}
.data-table .h4 {
  color: #46435d;
}
.checkbox-s {
  line-height: 3;
}
.list-item {
  border: none;
}
.inner-panel {
  padding-right: 25px;
}
.white {
  background-color: white;
}
.gray {
  background-color: #eee;
}
.app-search {
  justify-content: center;
}
.shadow {
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.w800 {
  width: 100% !important;
}
.w100 {
  width: 100% !important;
}
.capitals {
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #666666;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
  text-transform: uppercase;
}
.datatable-row-detail {
  background-color: white !important;
}
.title-color {
  color: #a3a6b3;
  font-size: 14px;
}
.head-table-title {
  padding-bottom: 15px;
  padding-top: 50px;
  padding-left: 0px;
}
.second-table-position {
  width: 85%;
  margin: 0 auto;
}
.third-table-position {
  padding: 10px 7.5%;
  margin: -10px;
}
.head-second-table {
  margin-left: 12px;
}
.head-color {
  background-color: #f8f8f8;
  height: 50px;
  padding-top: 15px;
}
.inner-table > ngx-datatable {
  box-shadow: none !important;
}
.inner-table datatable-header {
  background: #f5f7fa;
}
.inner-table datatable-body-row {
  border-bottom-color: #f5f7fa !important;
}
.inner-table-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
}
.export-button {
  float: right;
  margin-top: 30px;
  margin-bottom: 30px;
}
.export-button-padding {
  padding-left: 30px;
  padding-right: 30px;
}
.sr {
  width: 230px;
  justify-content: flex-start !important;
}
.f-r {
  width: 194px;
  display: flex;
  justify-content: flex-end;
}
.inner-table-sale .datatable-body-cell-label {
  width: 100% !important;
  padding: 0.9rem 1.2rem;
}
.inner-table-sale .datatable-row-center {
  padding: 0px !important;
}
.border-left-style {
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  border-left-style: solid;
}
.detail-dropdrown {
  padding-left: 10px;
  width: 197px;
}
.dropdown-menu.detail-dropdrown .nav-link.action_disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: #a3a6b3;
}
.dropdown-menu.detail-dropdrown .nav-link.action_enabled {
  pointer-events: auto;
  color: #656565;
  cursor: pointer;
}
.dropdown-menu.detail-dropdrown .nav-link.action_enabled:hover {
  color: #1b72e2;
}
@media (min-width: 768px) {
  .guide .popover {
    max-width: 500px;
  }
}
.user-info.user-info-block {
  max-width: 100%;
  display: inline-block;
  padding: 3px 9px;
  white-space: normal;
  line-height: 1.2;
  border-radius: 9px;
  text-align: center;
}
.guide-title {
  font-weight: bold;
}
.info-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
}
.payment-icon {
  display: inline-block;
  width: auto;
  height: 25px;
  margin: 2px;
}
.payment-country-icon {
  display: inline-block;
  width: auto;
  height: 25px;
  margin: 2px;
}
.secondary-text-color {
  color: #666666;
}
@keyframes highlight {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #1797be;
  }
  100% {
    background-color: transparent;
  }
}
.guide-highlight {
  margin: -0.5rem;
  padding: 0.5rem;
  animation: highlight 2s 3;
}
/*# sourceMappingURL=sale-analysis.component.css.map */
