<div class="wrapper page container">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'seller.title' | translate}}</h3>
      <button type="button" [routerLink]="['./new/seller']" class="btn blue-bg header-button float-right">{{'nav.new-seller'
        |
        translate}}</button>
    </div>
  </div>
  <div class="card">
    <div class="w100 app-search">
      <div class="w800">
        <!-- <app-advance-search [page]="locationPage" [sortOptions]="sortOptions" [sortOptionsPlaceholder]="'profile.sort'"
          [selectOptionsPlaceholder]="'profile.selectUsers'" (searchEvent)="searchEvent($event)"
          (sortEvent)="sortEvent($event)"></app-advance-search> -->

        <!-- <app-filter-search [page]="'seller-list'" (searchEvent)="searchEvent($event)"></app-filter-search> -->

        <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
          <form [formGroup]="searchForm">
            <div class="row">
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.name" [label]="'seller.name'" [placeholder]="'seller.name'"></sp-input-text>
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.email" [label]="'seller.email'" [placeholder]="'seller.email'"></sp-input-text>
              <sp-input-merchant-status-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.status" [multiple]="true" [label]="'general.state'" [placeholder]="'advance-search.select_state'"></sp-input-merchant-status-select>
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.vat" [label]="'seller.vat_number'" [placeholder]="'seller.vat_number'"></sp-input-text>
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.sdi" [label]="'seller.sdi_code'" [placeholder]="'seller.sdi_code'"></sp-input-text>
            </div>
          </form>
        </sp-filter-section>

      </div>
    </div>
    <div class="table-margin"><!--  -->
      <ngx-datatable #dataTable class='material outer-table expandable data-table' [columnMode]="'force'" [headerHeight]="0"
        [footerHeight]="70" [rowHeight]="100" [scrollbarH]="true" [scrollbarV]="false"  [rows]='rows' *ngIf="rows.length > 0"
        [limit]="tableRecordNumbers" (page)="onPage($event)" (select)='onSelect($event)' [sorts]="[{prop: sortProp, dir: 'asc'}]" [messages]="my_messages">
        <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <div class="white" *ngIf="row.sellerDetails">
              <div class="col-md-12 inner-panel padding-inner-table">
                <div class="row">
                  <div class="col-md-4">
                    <ul class="list-group list_like_table">
                      <li class="list-item list-group-item">{{ 'insert.reseller' | translate }} <b> {{getNameReseller(row.sellerDetails.resellerId)}}</b></li>
                      <li class="list-item list-group-item">{{'profile.vat_number' | translate}} <b> {{row.sellerDetails.vatNumber}}</b></li>
                      <!-- <li class="list-item list-group-item">{{'profile.fiscal_code' | translate}} <b> {{row.sellerDetails.fiscalCode}}</b> </li> -->
                      <li class="list-item list-group-item">{{'profile.email' | translate}} <b> {{row.sellerDetails.pecAddress}}</b></li>
                      <li class="list-item list-group-item">{{'profile.email_communication_short' | translate}} <b> {{row.sellerDetails.emailCommunication}}</b></li>
                      <li class="list-item list-group-item">{{'general.phone' | translate}} <b> {{row.sellerDetails.phoneNumber}}</b></li>
                      <li class="list-item list-group-item">{{ 'insert.country' | translate }} <b>{{row.sellerDetails.country}} </b></li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <ul class="list-group list_like_table">
                      <!-- <li class="list-item list-group-item">{{'general.celular' | translate}} <b> {{row.sellerDetails.telephone}}</b></li> -->
                      <li class="list-item list-group-item">{{'manage-user.days' | translate}} <b> {{row.sellerDetails.paymentDay}}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.acceptPrepaid == true">{{ 'general.label_prepaid' | translate }}<b> {{ 'seller.prepaidAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.acceptPrepaid != true">{{ 'general.label_prepaid' | translate }}<b> {{ 'seller.prepaidNotAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.amex == true">{{ 'general.label_amex' | translate }}<b> {{ 'seller.amexAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.amex != true">{{ 'general.label_amex' | translate }}<b> {{ 'seller.amexNotAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.sepa == true">{{ 'general.label_sepa' | translate }}<b> {{ 'seller.sepaAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.sepa != true">{{ 'general.label_sepa' | translate }}<b> {{ 'seller.sepaNotAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.paypal == true">{{ 'general.label_paypal' | translate }}<b> {{ 'seller.paypalAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.paypal != true">{{ 'general.label_paypal' | translate }}<b> {{ 'seller.paypalNotAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.klarna == true">{{ 'general.label_klarna' | translate }}<b> {{ 'seller.klarnaAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.klarna != true">{{ 'general.label_klarna' | translate }}<b> {{ 'seller.klarnaNotAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.satispay == true">{{ 'general.label_satispay' | translate }}<b> {{ 'seller.satispayAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item" *ngIf="row.sellerDetails.satispay != true">{{ 'general.label_satispay' | translate }}<b> {{ 'seller.satispayNotAllowed' | translate }}</b> </li>
                      <li class="list-item list-group-item">{{ 'insert.placeholder_codice_sdi' | translate }} <b> {{row.sellerDetails.sdi}}</b> </li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <ul class="list-group list_like_table">
                      <li class="list-item list-group-item">{{ 'insert.fixedFee' | translate }} <b>{{row.sellerDetails.fixedFee | number:'.2-2'}} <span style="font-size: 9px; font-weight:400;">{{row.currency}} {{ 'general.vat_excl' | translate }}</span></b></li>
                      <li class="list-item list-group-item">{{ 'insert.percentageFee' | translate }} <b>{{row.sellerDetails.percentageFee | number:'.2-2'}} <span style="font-size: 9px; font-weight:400;">% {{ 'general.vat_excl' | translate }}</span></b> </li>
                      <li class="list-item list-group-item">{{ 'insert.fixedFeeSmall' | translate }} <b>{{row.sellerDetails.fixedFeeSmall | number:'.2-2'}} <span style="font-size: 9px; font-weight:400;">{{row.currency}} {{ 'general.vat_excl' | translate }}</span></b></li>
                      <li class="list-item list-group-item">{{ 'insert.percentageFeeSmall' | translate }} <b>{{row.sellerDetails.percentageFeeSmall | number:'.2-2'}} <span style="font-size: 9px; font-weight:400;">% {{ 'general.vat_excl' | translate }}</span></b></li>
                      <li class="list-item list-group-item">{{ 'insert.fee_small_limit' | translate }} <b>{{row.sellerDetails.feeSmallMaxAmount | number:'.2-2'}} <span style="font-size: 9px; font-weight:400;">{{row.currency}}</span></b></li>
                      <li class="list-item list-group-item">{{ 'insert.transferFee' | translate }} <b>{{row.sellerDetails.transferFee | number:'.2-2'}} <span style="font-size: 9px; font-weight:400;">{{row.currency}} {{ 'general.vat_excl' | translate }}</span></b></li>
                      <li class="list-item list-group-item">{{ 'insert.transfer_balance_limit' | translate }} <b>{{row.sellerDetails.transferBalanceMinAmount | number:'.2-2'}} <span style="font-size: 9px; font-weight:400;">{{row.currency}}</span></b></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="table-wrapper padding-inner-table"> <!-- *ngIf="row.list.length > 0" -->
                <!-- <ngx-datatable  #table class='material expandable data-table' [columnMode]="'force'" [headerHeight]="0"
                  [footerHeight]="50" [rowHeight]="100" [scrollbarH]="true" [scrollbarV]="false"
                  [rows]='row.list'  [limit]="tableRecordNumbers" (page)="onPage($event)" (select)='onSelect($event)'>
                  <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                      <div class="white">
                        <div class="col-md-12 inner-panel padding-inner-table">
                          <div class="row" *ngIf="row.userDetails">
                            <div class="col-md-4">
                              <ul class="list-group">
                                <li class="list-item list-group-item">{{'profile.vat_number' | translate}} <b>
                                    {{row.userDetails.vatNumber}}</b> </li>
                                <li class="list-item list-group-item">{{'profile.fiscal_code' | translate}} <b>
                                    {{row.userDetails.fiscalCode}}</b> </li>
                                <li class="list-item list-group-item">{{'profile.email' | translate}} <b> {{row.userDetails.email}}</b></li>
                              </ul>
                            </div>
                            <div class="col-md-8">
                              <ul class="list-group">
                                <li class="list-item list-group-item">{{'general.celular' | translate}} <b>
                                    {{row.userDetails.telephone}}</b></li>
                                <li class="list-item list-group-item">{{'general.phone' | translate}} <b> {{row.userDetails.phoneNumber}}</b></li>
                                <li class="list-item list-group-item">{{'manage-user.days' | translate}} <b>
                                    {{row.userDetails.paymentDay}}</b> </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-row-detail>
                  <ngx-datatable-column name="Name" [width]="250">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                      <div class="row">
                        <div class="col-2" *ngIf="row.profile != 'RESELLER'">
                            <p class="capitals start" *ngIf="row.name && row.surname"> {{row.name[0].charAt(0)}}{{row.surname[0].charAt(0)}}</p>
                        </div>
                        <div class="col-2" *ngIf="row.profile == 'RESELLER'">
                          <p class="capitals start" *ngIf="row.name">
                            {{row.name[0].charAt(0)}}</p>
                        </div>
                        <div  class="col-10">
                          <h4>{{row.companyName}}</h4>
                          <div class="secondary-text-color">{{row.pecAddress}}</div>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="200">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                      <div class="a-center">
                        <div class="user-info dark-bg">{{row.currency}}</div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Status" [width]="250">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                      <div class="row center-row">
                        <div>
                          <div class="status-div status-div-table {{row.currency}}">{{row.phoneNumber}}</div>
                        </div>
                        <div class="status-pencil">
                          <app-status-trash [allStatus]="allStatus" (hideSelectEvent)="hideSelectEvent(row)" (statusSelectedEvent)="statusSelectedEvent($event,row)"></app-status-trash>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="300" [resizeable]="false" [sortable]="false" [draggable]="false"
                    [canAutoResize]="false">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                      <div class="f-right">
                        <button class="blue-bg details-button" title="Expand/Collapse Row" (click)="toggleExpandRowMerchant(row,expanded)">
                          <span *ngIf="!expanded">{{'sale.details_button' | translate}}</span>
                          <span *ngIf="expanded">{{'general.close' | translate}}</span>
                        </button>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable> -->
              </div>
            </div>
          </ng-template>
        </ngx-datatable-row-detail>
        <ngx-datatable-column name="Name" [width]="250">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="row">
              <div class="col-2">
                <!--<p class="capitals start" *ngIf="row.companyName && row.description"> {{row.companyName[0].charAt(0)}}{{row.description[0].charAt(0)}}</p>
                <p class="capitals start" *ngIf="row.companyName && !row.description"> {{row.companyName[0].charAt(0)}}{{row.companyName[1].charAt(0)}}</p>-->
                <div class="status-circle {{row.state}}" [title]="'seller.status.' + row.state | translate"></div>
              </div>
              <div class="col-10">
                <h4 class="text-clamp" title="{{row.companyName}}">{{row.companyName}}</h4>
                <div class="secondary-text-color">{{row.pecAddress}}</div>
                <div class="secondary-text-color" *ngIf="row.emailCommunication">{{row.emailCommunication}}</div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="row center-row">
              <div>
                <div class="status-div dark-bg">{{row.currency}}</div>
                <div class="status-div light-bg" style="font-size: 12px;" *ngIf="row.idVat">{{ 'insert.vat' | translate }} {{row.vatValue}} %</div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" [width]="250">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="row center-row"> 
              <div class="col-12 text-wrap">
                <img class="payment-icon" *ngIf="row.acceptPrepaid" src="../../../../assets/img/payment-icons/card_placeholder1.png">
                <img class="payment-icon" *ngIf="row.amex" src="../../../../assets/img/payment-icons/amex.png">
                <img class="payment-icon" *ngIf="row.sepa" src="../../../../assets/img/payment-icons/sepa.png">
                <img class="payment-icon" *ngIf="row.paypal" src="../../../../assets/img/payment-icons/paypal.png">
                <img class="payment-icon" *ngIf="row.klarna" src="../../../../assets/img/payment-icons/klarna.png">
                <img class="payment-icon" *ngIf="row.satispay" src="../../../../assets/img/payment-icons/satispay.png">
              </div>
              <!--<div>
                <div class="status-div status-div-table  {{row.currency}}">{{row.phoneNumber}}</div>
              </div>
              <div class="status-pencil">
                  <app-status-trash [allStatus]="allStatus" (hideSelectEvent)="hideSelectEvent(row)" (statusSelectedEvent)="statusSelectedEvent($event,row)"></app-status-trash>
              </div>-->
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <div class="f-right">
              <button class="blue-bg details-button" title="Expand/Collapse Row" (click)="toggleExpandRow(row,expanded)">
                <span *ngIf="!expanded">{{'sale.details_button' | translate}}</span>
                <span *ngIf="expanded">{{'general.close' | translate}}</span>
              </button>
              <a href="#" class="moredd dropdown-toggle nav-link blue-bg sec-butt" data-bs-toggle="dropdown" role="button"
                aria-expanded="false">
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu detail-dropdrown" role="menu">
                <li class="nav-item">
                    <!-- <a class="nav-link edit" [routerLink]="['./edit/seller/'+row.userId]">{{'sale.modificate-user' | translate}}</a> -->
                    <a class="nav-link edit" [routerLink]="['./edit/seller/'+row.idMerchant]">{{'seller.modificate-user' | translate}}</a>
                    <a class="nav-link edit" [routerLink]="[row.idMerchant+'/manage-fees/']">{{'seller.manage-fees' | translate}}</a>
                    <a class="nav-link edit action_enabled" (click)="showTosAcceptanceLink( row.tosLink )">{{'seller.show-tos-acceptance-link' | translate}}</a>
                </li>
              </ul>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <div class="" *ngIf="rows.length == 0">
        <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
      </div>
    </div>
  </div>
</div>
