<div class="container">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left">{{ 'insert.new_profile' | translate }}</h3>
      <button type="button" class="btn btn-danger float-right button-style" (click)="navigationBack()">{{'general.exit' |
        translate}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-xs-12 white-row">
      <div class="row col-md-12 col-xs-12">
        <div class="col-md-6 col-xs-12 padding-right">
          <form (ngSubmit)="onSubmit()" #formLogin="ngForm" class="form-validate mb-3" role="form" name="formProfile"
            novalidate="">
            <div class="input-group with-focus">
              <input class="form-control control no-border" id="profile" ngModel name="profile" required profile
                #profile="ngModel" placeholder="{{'profile.profile_name' | translate}}" />
            </div>
            <div class="input-group with-focus form-padding-top">
              <input type="number" class="form-control control" id="rate" ngModel name="rate" required profile #rate="ngModel"
                placeholder=" {{ 'profile.n_installment' | translate }}" />
            </div>
          </form>
          <div class=" col-xs-12 dropdown filter-dd dd-padding-top">
            <button class="btn-block filter-dd-button btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ 'profile.frequency_rate' | translate }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a (click)="selectOption(dropdown)" *ngFor="let dropdown of dropdowns" class="dropdown-item">{{dropdown.name}}</a>
            </div>
          </div>
          <form (ngSubmit)="onSubmit()" #formLogin="ngForm" class="form-validate mb-3" role="form" name="formProfile"
            novalidate="">
            <div class="input-group with-focus padding-top">
              <input class="form-control control" type="number" id="rate" ngModel name="rate" required rate #rate="ngModel"
                placeholder="{{'profile.installment_begin' | translate}}" />
            </div>
          </form>
          <div class="col-xs-12 dropdown filter-dd dd-padding-top">
            <button class="btn-block filter-dd-button btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ 'profile.first_installment' | translate }}
            </button>
            <div class="dropdown-menu"aria-labelledby="dropdownMenuButton">
              <a (click)="selectOption(dropdown)" *ngFor="let dropdown of dropdowns" class="dropdown-item">{{dropdown.name}}</a>
            </div>
          </div>
          <div class="col-xs-12 dropdown filter-dd padding-top">
            <button class="btn-block filter-dd-button btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ 'profile.tacit' | translate }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a (click)="selectOption(dropdown)" *ngFor="let dropdown of dropdowns" class="dropdown-item">{{dropdown.name}}</a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
        </div>
        <div class="g-button-position">
          <button class="btn btn-primary green-button" type="submit">{{'profile.create' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
