<div class="card">
  <div class="w100 app-search">
    <div class="w800">
      
      <!-- <app-advance-search 
        [intervalDropdownsPlaceholder]="'profile.interval'" [selectOptionsPlaceholder]="'profile.selectUsers'"
        [selectOptions]="selectOptions" (searchEvent)="searchEvent($event)" (deletedChip)="chipDeleted()" (intervalEvent)="intervalEvent($event)"
        [options]="options" (exclusionEvent)="onExclusionChange($event)" [expiredOptions]="expiredOptions" (changeAlert)="initRateTable('1')" [page]="'rate-analysis'"></app-advance-search> --> <!-- [intervalOptions]="intervalOptions" -->

      <!--<app-filter-search (searchEvent)="searchEvent($event)" [page]="'refund-analysis'">
      </app-filter-search>-->

      <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
        <form [formGroup]="searchForm">
          <div class="row">
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerName" [label]="'general.name_nominative'" [placeholder]="'general.name_nominative'"></sp-input-text>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerSurname" [label]="'general.surname'" [placeholder]="'general.surname'"></sp-input-text>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerEmail" [label]="'general.email'" [placeholder]="'general.email'"></sp-input-text>
            <sp-input-text-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.amountFrom" [controlTo]="searchForm.controls.amountTo" [type]="'number'"
              [label]="'general.import'" [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-text-range>
            <!--<sp-input-datepicker class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangeRequest" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_request'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker>-->
            <!--<sp-input-datepicker-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.dateFromRequest" [controlTo]="searchForm.controls.dateToRequest" [showClearButton]="true" [autoApply]="false" [label]="'general.date_request'"
              [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-datepicker-range>-->
            <sp-input-datepicker-bootstrap class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangeRequest" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_request'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker-bootstrap>
            <!--<sp-input-datepicker class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangeRefund" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_refunded'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker>-->
            <!--<sp-input-datepicker-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.dateFromRefund" [controlTo]="searchForm.controls.dateToRefund" [showClearButton]="true" [autoApply]="false" [label]="'general.date_refunded'"
              [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-datepicker-range>-->
            <sp-input-datepicker-bootstrap class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangeRefund" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_refunded'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker-bootstrap>
            <sp-input-instalment-refund-status-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.status" [multiple]="true" [label]="'general.state'" [placeholder]="'advance-search.select_state'"></sp-input-instalment-refund-status-select>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.userEmail" [label]="'general.creator_email'" [placeholder]="'general.creator_email'"></sp-input-text>
            <sp-input-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idMerchant" [label]="'general.merchant'" [placeholder]="'advance-search.select_merchant'"
              *ngIf="(profile=='SUPERADMIN' || profile=='RESELLER') && !selectedUser"></sp-input-merchant-select>
            <sp-input-external-application-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idExternal" [idMerchant]="searchForm.controls.idMerchant.value"
              [label]="'general.external_application'" [placeholder]="'advance-search.select_external_application'"></sp-input-external-application-merchant-select>
          </div>
        </form>
        <form [formGroup]="sortForm">
          <div class="row">
            <sp-input-checkbox class="col-md-4 col-sm-6 mt-2 align-self-end" [control]="sortForm.controls.viewRefund" [label]="'advance-search.includi_rimborsi_rate'" [id]="'viewRefund'" (itemToggled)="onRefundExclusionChange($event)"></sp-input-checkbox>
            <sp-input-checkbox class="col-md-4 col-sm-6 mt-2 align-self-end" [control]="sortForm.controls.viewDispute" [label]="'advance-search.includi_contestazioni_rate'" [id]="'viewDispute'" (itemToggled)="onDisputeExclusionChange($event)"></sp-input-checkbox>
          </div>
        </form>
      </sp-filter-section>

    </div>
  </div>
  <div class="table-margin">
    <ngx-datatable #dataTable class='material outer-table expandable data-table' [columnMode]="'force'"
      [headerHeight]="0" [footerHeight]="70" [rowHeight]="120" [scrollbarH]="true" [scrollbarV]="false" [rows]="rows" [count]="totalRows"
      *ngIf="rows.length > 0" [externalPaging]="true" [limit]="tableRecordNumbers" [offset]="tablePage - 1" (page)="onPage($event)" [messages]="my_messages">
      <ngx-datatable-row-detail #myDetailRow>
        <ng-template let-row="row" let-superRow="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="white second-table-position" *ngIf="row.installmentDetails && row.installmentDetails.length">
            <div class="row col-md-12 rate-inner-table-title in-d">{{'sale.rate_details' | translate}}</div>
            <div class="table-wrapper rate-inner-table inner-table-rate">
              <ngx-datatable #table class='material expandable data-table' [columnMode]="'force'" [headerHeight]="50"
                [footerHeight]="50" [rowHeight]="70" [scrollbarH]="true" [scrollbarV]="false" [rows]="row.installmentDetails"
                [limit]="tableRecordNumbers" [messages]="my_messages">
                <ngx-datatable-column name="N°" [width]="60" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                    <div>{{rowIndex + 1}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.state' | translate}}" [width]="170" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                      <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{ 'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="100" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.expiry' | translate}}" [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                      <ng-container *ngIf="row.expiryDate">{{row.expiryDate | date:'dd/MM/yyyy'}}</ng-container>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.collected' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                      <ng-container *ngIf="row.lastTransaction">
                        <ng-container *ngIf="row.instalmentStatus == 'PAID' || row.instalmentStatus == 'DISPUTE' || row.instalmentStatus == 'DISPUTED' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY'">
                          {{row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'}}
                          <ng-container *ngIf="row.paymentMethodType">
                            <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                              title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                            <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                              title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                            <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                              title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                            <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                              row.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                              title="{{row.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                            <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}}">
                            <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
                            <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
                            <img class="payment-country-icon ml-1" *ngIf="row.paymentMethodCountry" src="../../../../assets/img/country/{{row.paymentMethodCountry}}.png">
                          </ng-container>
                        </ng-container>
                        <div class="font-size-style text-danger" *ngIf="row.instalmentStatus == 'ERROR'">
                          {{ 'sale.instalment_payment_error' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss',
                            result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}
                        </div>
                        <div class="font-size-style text-warning" *ngIf="row.instalmentStatus == 'PROCESSING'">
                          {{ 'sale.instalment_payment_processing' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'} }}
                        </div>
                        <ng-container *ngIf="row.instalmentStatus != 'PAID' && row.instalmentStatus != 'ERROR' && row.instalmentStatus != 'PROCESSING' && row.instalmentStatus != 'DISPUTE' && row.instalmentStatus != 'DISPUTED' && row.instalmentStatus != 'ONGOING_INQUIRY' && row.instalmentStatus != 'CLOSED_INQUIRY'">-</ng-container>
                      </ng-container>
                      <ng-container *ngIf="!row.lastTransaction">-</ng-container>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <!-- <ngx-datatable-footer *ngIf="true">
                  <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" >
                    
                    <div *ngIf="rowCount > 0">
                      {{ rowCount }} {{ 'datatable_message.lista_totali_righe' | translate }}
                    </div>
                    <div *ngIf="rowCount == 0">
                      {{ 'datatable_message.lista_vuota_righe' | translate }}
                    </div>
                    <datatable-pager
                      [pagerLeftArrowIcon]="'datatable-icon-left'"
                      [pagerRightArrowIcon]="'datatable-icon-right'"
                      [pagerPreviousIcon]="'datatable-icon-prev'"
                      [pagerNextIcon]="'datatable-icon-skip'"
                      [page]="curPage"
                      [size]="pageSize"
                      [count]="rowCount"
                      [hidden]="!((rowCount / pageSize) > 1)"
                      (change)="table.onFooterPage($event)">
                    </datatable-pager>
                  </ng-template>
                </ngx-datatable-footer> -->

              </ngx-datatable>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column [width]="120">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div>
            <h4>{{row.amount}} <span style="font-size: 12px;">{{row.currency}}</span></h4>
            <div class="table-font-size" *ngIf="!isDebitInstalment(row)">{{'sale.refund_import' | translate}}</div>
            <div class="table-font-size" *ngIf="isDebitInstalment(row)">{{'sale.debit_import' | translate}}</div>
            <div class="user-info user-info-block {{row.instalmentStatus}} mx-0 mt-1">{{ 'sale.rate_detail_status.'+row.instalmentStatus | translate }}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="110">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <h4>{{row.expiryDate | date: 'dd/MM/yyyy'}}</h4>
            <div class="table-font-size">{{'sale.request_date' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="110">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <ng-container *ngIf="row.instalmentStatus != 'ERROR_REFUND' && row.instalmentStatus != 'CANCELED_REFUND' && row.instalmentStatus != 'PLANNED_REFUND'">
              <h4 class="mb-0">{{row.tsPayment | date: 'dd/MM/yyyy'}}</h4>
              <h4 class="mb-1">{{row.tsPayment | date: 'HH:mm:ss'}}</h4>
            </ng-container>
            <h4 *ngIf="(row.instalmentStatus == 'PLANNED_REFUND' || row.instalmentStatus == 'ERROR_REFUND') && row.lastTransaction">
              <i class="fa fa-exclamation-circle" placement="right" popover="{{ 'sale.rata_detail_popover_desc' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss' ,
                result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}"
                popoverTitle="{{ 'sale.rate_detail_popover_title' | translate }}" [outsideClick]="true"></i></h4> <!--  style="color: #ff902b;" -->
            <h4 *ngIf="row.instalmentStatus == 'CANCELED_REFUND' || !row.lastTransaction">-</h4>
            <div class="table-font-size" *ngIf="!isDebitInstalment(row)">{{'sale.refunded_date' | translate}}</div>
            <div class="table-font-size" *ngIf="isDebitInstalment(row)">{{'sale.debited_date' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="220">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="border-left-style pl-4">
            <h4 class="text-clamp" title="{{row.service}}">{{row.service}}</h4>
            <div class="table-font-size">
              <ng-container *ngIf="row.fullName">{{row.fullName}}</ng-container>
              <ng-container *ngIf="!row.fullName">{{row.name}} {{row.surname}}</ng-container>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="110">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <h4>{{row.saleDate | date: 'dd/MM/yyyy'}}</h4>
            <div class="table-font-size">{{'sale.sales_date' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="100">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <h4>{{row.saleAmount}} <span style="font-size: 12px;">{{row.currency}}</span></h4>
            <div class="table-font-size">{{'sale.total_amount' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="130">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <div class="status-div status-div-table {{row.saleStatus}} m-0">{{ 'sale.sale_status.'+row.saleStatus | lowercase | translate }}</div>
            <div class="table-font-size">{{ 'sale.sales_state' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <div class="f-right">
            <button class="blue-bg details-button" (click)="toggleExpandRow(row,expanded)" [disabled]="!canViewDetails(row)">
              <span *ngIf="!expanded">{{'sale.instalment_det' | translate}}</span>
              <span *ngIf="expanded">{{'general.close' | translate}}</span>
            </button>
            <a href="#" class="moredd dropdown-toggle nav-link blue-bg sec-butt" data-bs-toggle="dropdown" role="button"
              aria-expanded="false" style=" text-decoration: none;">
              <span class="caret"></span>
            </a>
            <ul class="dropdown-menu detail-dropdrown" role="menu">
              <li class="nav-item" *ngIf="( row.instalmentStatus == 'PENDING_REFUND' && canCompleteRefundPayment(row) )">
                <a class="nav-link edit action_enabled" [class.action_enabled]="canCompleteRefundPayment(row)" (click)="completeRefundInstalment( row )">{{'sale.label_complete_refund_payment' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="!hasActionToShow(row)">
                <a class="nav-link edit action_disabled">{{'general.label_empty_options' | translate}}</a>
              </li>
            </ul>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- <ngx-datatable-footer *ngIf="true">
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" >
          <div *ngIf="rowCount > 0" style="padding-left: 20px;">
            {{ rowCount }} {{ 'datatable_message.lista_totali_righe' | translate }}
          </div>
          <div *ngIf="rowCount == 0" style="padding-left: 20px;">
            {{ 'datatable_message.lista_vuota_righe' | translate }}
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!((rowCount / pageSize) > 1)"
            (change)="table.onFooterPage($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer> -->

    </ngx-datatable>
    <div class="" *ngIf="rows.length == 0">
      <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
    </div>
  </div>
</div>
<div class="export-button">
  <button class="blue-bg details-button export-button-padding" (click)="export()">
    <span>{{'sale.export' | translate}}</span>
  </button>
</div>