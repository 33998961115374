<div class="container wrapper">

  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'seller.fee_configuration.title' | translate}} {{feeGeneral?.companyName}}</h3>
      <button type="button" class="btn btn-danger float-right button-style" (click)="navigationBack()">
        {{'general.back' | translate}}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{'seller.fee_configuration.new_configuration_title' | translate}}</h4>
          <form [formGroup]="configurationForm" class="">
            <div class="row">
              <div class="col-sm-6 col-md-4">
                <div class="has-float-label">
                  <ng-select class="custom select-profile" [items]="sourceProfiles" placeholder="{{'seller.fee_configuration.select_payment_profile' | translate}}*" formControlName="profile" (change)="onProfileSelected($event)" dropdownPosition="bottom">
                    <ng-template ng-label-tmp let-item="item">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <img class="img-fluid img-small" src="assets/img/payment-icons/{{item.data.brand}}.png" />
                        </div>
                        <div class="col pl-0 text-truncate">
                          {{item.channel}} - {{item.description}}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="row align-items-center">
                        <div class="col-auto px-2">
                          <img class="img-fluid" src="assets/img/payment-icons/{{item.data.brand}}.png" />
                        </div>
                        <div class="col pl-0 pr-2 text-truncate">
                          {{item.channel}} - {{item.description}}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="col-sm-3 col-md-3">
                <div class="has-float-label" *ngIf="selectedProfile != null">
                  <input class="custom-float-label-input" type="number" min="0" step="0.1" placeholder=" " formControlName="percentageFee" id="percentageFee">
                  <label class="label custom-label" for="percentageFee"> {{'seller.fee_configuration.percentage_fee' | translate}}* </label>
                </div>
              </div>
              <div class="col-sm-3 col-md-3">
                <div class="has-float-label" *ngIf="selectedProfile != null">
                  <input class="custom-float-label-input" type="number" min="0" step="0.1" placeholder=" " formControlName="fixedFee" id="fixedFee">
                  <label class="label custom-label" for="fixedFee"> {{'seller.fee_configuration.fixed_fee' | translate}}* </label>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-sm-3 col-md-3 col-lg-2">
                <button type="button" class="btn btn-block btn-primary" [disabled]="configurationForm.invalid" (click)="createConfiguration()">
                  {{'seller.fee_configuration.save_configuration' | translate}}
                </button>
              </div>
            </div>            
          </form>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="editConfigurationForm">
    <div formArrayName="configurations">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              
              <h4 class="card-title mb-4">
                {{'seller.fee_configuration.fee_configured' | translate}}
              </h4>

              <ng-container *ngFor="let item of editConfigurationForm.get('configurations')['controls']; let i=index">
                <div class="col-12" [formGroupName]="i">
                  <div class="card mb-3">
                    <div class="card-body">
                      
                      <div>
                        <div class="row align-items-center">
                          <div class="col-2 col-md-1">  
                            <img src="assets/img/payment-icons/{{sourceConfiguration[i].profile.data.brand}}.png" class="img-fluid" />
                          </div>
                          <div class="col-4 col-sm-3 col-md-2">
                            <p class="h4 mb-0">{{ item.controls.profile.value.description }}</p>
                          </div>
                          <div class="col-6 col-md-3">
                            <p class="">
                              <span>{{'seller.fee_configuration.percentage_fee' | translate}}</span><br>
                              <span class="h4" *ngIf="sourceConfiguration[i].isReadOnly">{{sourceConfiguration[i].percentageFee}} %</span>
                              <input formControlName="percentageFee" *ngIf="!sourceConfiguration[i].isReadOnly" class="custom-float-label-input" type="number" min="0" step="0.1" [ngClass]="{'read-only': sourceConfiguration[i].isReadOnly}" [readonly]="sourceConfiguration[i].isReadOnly">
                            </p>
                          </div>
                          <div class="col-6 col-md-2">
                            <p class="">
                              <span>{{'seller.fee_configuration.fixed_fee' | translate}}</span><br>
                              <span class="h4" *ngIf="sourceConfiguration[i].isReadOnly">{{sourceConfiguration[i].fixedFee}} <small>{{feeGeneral?.currency}}</small></span>
                              <input formControlName="fixedFee" *ngIf="!sourceConfiguration[i].isReadOnly" class="custom-float-label-input" type="number" min="0" step="0.1">
                            </p>
                          </div>
                          <div class="col-2">
                            <p class="mb-0">
                              <span>{{'seller.fee_configuration.status' | translate}}</span>
                            </p>
                            <div class="ml-auto my-1">
                              <label class="switch m-0 d-inline-flex">
                                <input type="checkbox" checked="{{ sourceConfiguration[i].enabled ? 'checked' : '' }}" (click)="toggleEnable(item)"/>
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-4 col-sm-3 col-md-2 text-right">
                            <button type="button" class="btn btn-block btn-primary" *ngIf="sourceConfiguration[i].isReadOnly" (click)="toggleEdit(item, sourceConfiguration[i])">
                              {{'general.edit' | translate}}
                            </button>
                            <button type="button" class="btn btn-block btn-primary" *ngIf="!sourceConfiguration[i].isReadOnly" [disabled]="item.invalid" (click)="updateConfiguration(item, sourceConfiguration[i])">
                              {{'general.update' | translate}}
                            </button>
                            <button type="button" class="btn btn-block btn-danger" *ngIf="sourceConfiguration[i].isReadOnly" (click)="deleteConfiguration( item )">
                              {{'general.delete' | translate}}
                            </button>
                            <button type="button" class="btn btn-block btn-outline-danger" *ngIf="!sourceConfiguration[i].isReadOnly" (click)="toggleEdit(item, sourceConfiguration[i])">
                              {{'general.cancel' | translate}}
                            </button>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
        
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{'seller.fee_configuration.general_configuration_label' | translate}}</h4>
          <div class="row align-items-center">
            <div class="col-2 col-md-1">
              <img src="assets/img/payment-icons/card_placeholder1.png" class="img-fluid" />
            </div>
            <div class="col-auto">
              <p class="">
                <span>{{'seller.fee_configuration.percentage_fee' | translate}}</span><br>
                <span class="h4">{{feeGeneral?.percentageFee}} %</span>
                <span class="h5 ml-2">({{feeGeneral?.percentageFeeSmall}} %)</span>
              </p>
            </div>
            <div class="col-auto">
              <p class="">
                <span>{{'seller.fee_configuration.fixed_fee' | translate}}</span><br>
                <span class="h4">{{feeGeneral?.fixedFee}} <small>{{feeGeneral?.currency}}</small></span>
                <span class="h5 ml-2">({{feeGeneral?.fixedFeeSmall}} <small>{{feeGeneral?.currency}}</small>)</span>
              </p>
            </div>
            <div class="col-auto mw-100">
              <div class="row">
                <div class="col-auto">
                  <p class="">
                    <span>{{'seller.fee_configuration.prepaid' | translate}}</span><br>
                    <span class="h4" *ngIf="feeGeneral?.acceptPrepaid">{{ 'seller.fee_configuration.accepted_female' | translate }}</span>
                    <span class="h4" *ngIf="!feeGeneral?.acceptPrepaid">{{ 'seller.fee_configuration.refused_female' | translate }}</span>
                  </p>
                </div>
                <div class="col-auto">
                  <p class="">
                    <span>{{ 'seller.fee_configuration.amex' | translate }}</span><br>
                    <span class="h4" *ngIf="feeGeneral?.amex">{{ 'seller.fee_configuration.accepted_female' | translate }}</span>
                    <span class="h4" *ngIf="!feeGeneral?.amex">{{ 'seller.fee_configuration.refused_female' | translate }}</span>
                  </p>
                </div>
                <div class="col-auto">
                  <p class="">
                    <span>{{ 'seller.fee_configuration.sepa' | translate }}</span><br>
                    <span class="h4" *ngIf="feeGeneral?.sepa">{{ 'seller.fee_configuration.accepted_male' | translate }}</span>
                    <span class="h4" *ngIf="!feeGeneral?.sepa">{{ 'seller.fee_configuration.refused_male' | translate }}</span>
                  </p>
                </div>
                <div class="col-auto">
                  <p class="">
                    <span>{{ 'seller.fee_configuration.paypal' | translate }}</span><br>
                    <span class="h4" *ngIf="feeGeneral?.paypal">{{ 'seller.fee_configuration.accepted_male' | translate }}</span>
                    <span class="h4" *ngIf="!feeGeneral?.paypal">{{ 'seller.fee_configuration.refused_male' | translate }}</span>
                  </p>
                </div>
                <div class="col-auto">
                  <p class="">
                    <span>{{ 'seller.fee_configuration.klarna' | translate }}</span><br>
                    <span class="h4" *ngIf="feeGeneral?.klarna">{{ 'seller.fee_configuration.accepted_male' | translate }}</span>
                    <span class="h4" *ngIf="!feeGeneral?.klarna">{{ 'seller.fee_configuration.refused_male' | translate }}</span>
                  </p>
                </div>
                <div class="col-auto">
                  <p class="">
                    <span>{{ 'seller.fee_configuration.satispay' | translate }}</span><br>
                    <span class="h4" *ngIf="feeGeneral?.satispay">{{ 'seller.fee_configuration.accepted_male' | translate }}</span>
                    <span class="h4" *ngIf="!feeGeneral?.satispay">{{ 'seller.fee_configuration.refused_male' | translate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

</div>